import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'ngx-moment'; // optional, provides moment-style pipes for date formatting
import { ModalModule } from 'ngx-bootstrap/modal';
import { AppComponent } from './app.component';
import { AppConfigService } from './core/services/app-config.service';
//import { AdpaIntegrationService } from './core/services/adpa-integration.service'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AppRoutingModule} from './app.routing';
import { RebarAuthModule } from './core/rebarauth/rebar.auth.module';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ErrorComponent } from './shared/error/error.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { PopUpComponent } from './feature/pop-up/pop-up.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DeviceDetectorService } from 'ngx-device-detector';
import {  MatomoModule } from 'ngx-matomo';
import { FeedbackComponent } from './feature/feedback/feedback.component';
import {ModuleMapLoaderModule} from '@nguniversal/module-map-ngfactory-loader';
import { MsalRedirectComponent, MsalModule } from '@azure/msal-angular';
import { FeedbackModule } from './feature/feedback/feedback.module';
//import { ProgressBarModule } from 'angular-progress-bar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';




// import { AppInsightsService } from './core/services/app-insights.service';
// import { CustomdashboardV2Component } from './feature/customdashboard-v2/customdashboard-v2.component';

// import { CustomdashboardComponent } from './customdashboard/customdashboard.component';
// import { NewdashboardComponent } from './feature/newdashboard/newdashboard.component';


// import { DashboardComponent } from './dashboard/dashboard.component';


@NgModule({ declarations: [
        AppComponent,
        PageNotFoundComponent,
        HeaderComponent,
        FooterComponent,
        ErrorComponent,
        PopUpComponent,
        // FeedbackComponent,
        // CustomdashboardV2Component,
        // EmailReminderComponent
        // CustomdashboardComponent
        // NewdashboardComponent
    ],
    schemas: [
        NO_ERRORS_SCHEMA
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [BrowserModule, FormsModule, BrowserAnimationsModule, FeedbackModule,
        //routing,
        AppRoutingModule,
        ReactiveFormsModule,
        MatDialogModule, NgMultiSelectDropDownModule, BrowserAnimationsModule, DragDropModule,
        NgIdleKeepaliveModule.forRoot(), ModalModule.forRoot(),
        MomentModule,
        RebarAuthModule.forRoot(), MatomoModule.forRoot({
            scriptUrl: '//matomo.example.com/matomo.js',
            trackers: [
                {
                    trackerUrl: 'http://matomo.example.com/matomo.php',
                    siteId: 1
                }
            ],
            routeTracking: {
                enable: true
            }
        }),
        MsalModule //, ProgressBarModule
        ,
        NgbModule], providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (config: AppConfigService) => () => config.load(),
            deps: [AppConfigService], multi: true
        }, // , AppInsightsService
        //AdpaIntegrationService
        DeviceDetectorService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
